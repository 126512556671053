var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" rounded container"},[_c('div',{staticClass:" text-1000rem sm:text-xl text-center sm:px-4 px-1 mb-4"},[_vm._v(_vm._s(_vm.obj.desc))]),_vm._l((_vm.obj.message),function(item){return _c('div',{key:item.mid},[_c('div',{staticClass:"mutiple_form_layout"},[_c('a-form-model-item',{style:(_vm.GetScreenStyle.width45percent),attrs:{"label":"Id number(证件号码)","prop":item.proof_num_str}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"value":item.proof_num},on:{"change":function (e) {
            _vm.handleInput(e, item.proof_num_str);
          }}})],1),_c('a-form-model-item',{style:(_vm.GetScreenStyle.width45percent),attrs:{"label":"Issue Country(所属国家)","prop":item.belongs_to_str}},[_c('a-select',{attrs:{"disabled":item.belongs_to_str_disabled || _vm.disabled,"value":item.belongs_to},on:{"change":function (value) {
            _vm.handleSelect(value, item.belongs_to_str);
          }}},_vm._l((item.country_selection),function(item){return _c('a-select-option',{key:item.code,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),(item.issue_date_str)?_c('div',{staticClass:"mutiple_form_layout"},[_c('a-form-model-item',{style:(_vm.GetScreenStyle.width45percent),attrs:{"label":"Issue Date(签发日期)","required":"","prop":item.issue_date_str}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled,"inputReadOnly":true,"value":_vm.filterValue(item.issue_date)},on:{"change":function (e){
          _vm.handleDatePick(e, item.issue_date_str)
        }}})],1),(item.expiry_date_str)?_c('a-form-model-item',{style:(_vm.GetScreenStyle.width45percent),attrs:{"label":"Expiry Date(失效日期)","required":"","prop":item.expiry_date_str}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"inputReadOnly":true,"disabled":_vm.disabled,"value":_vm.filterValue(item.expiry_date)},on:{"change":function (e){
          _vm.handleDatePick(e, item.expiry_date_str)
        }}})],1):_vm._e()],1):_vm._e()])}),_vm._l((_vm.obj.upload),function(item){return _c('AUploadFile',{key:item.fid,attrs:{"title":item.title,"prop":item.prop,"fileList":item.file,"disabled":_vm.disabled},on:{"FileValidate":_vm.FileValidate,"handleFileChange":_vm.handleFileChange}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }